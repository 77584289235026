<template>
	<div class="c-block-list-cta-button-custom max-w-864 mx-auto">
		<div
			:class="[
				'flex flex-wrap gap-x-sm gap-y-xs items-stretch justify-center',
			]"
		>
			<NuxtLinkExt
				v-for="(item, index) in link"
				:key="`cta-${index}`"
				class="block w-fit"
				:to="item.url"
				:target="item.target"
			>
				<BaseButton tag="div" class="w-fit">
					<template #default>{{ item.name }}</template>
					<template
						v-if="item.type === 'url' || item.type === 'media'"
						#icon
					>
						<SvgArrow
							v-if="item.type === 'url'"
							class="transform -rotate-45"
						/>
						<SvgArrow
							v-if="item.type === 'media'"
							class="transform -rotate-45"
						/>
					</template>
				</BaseButton>
			</NuxtLinkExt>
		</div>
	</div>
</template>

<script>
import SvgArrow from '~/assets/svgs/arrow-icon.svg?inline';

export default {
	name: 'BlockListCtaButtonCustom',
	components: { SvgArrow },
	inheritAttrs: false,

	props: {
		link: {
			type: Array,
			required: true,
		},
	},
};
</script>

<style lang="postcss">
.c-block-list-rte-custom + .c-block-list-cta-button-custom {
	@apply w-5col >=768:w-6col mt-sm;
}
</style>
